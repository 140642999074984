import logo from './logo.svg';
import './App.css';
import Header from './component/Header';
import "./assets/style/style.css";
import PublicRoutes from './pages/routes/PublicRoutes';

function App() {
  return (
  <>
  <PublicRoutes />
  </>
  );
}

export default App;
